.header-background {
    background: #F8F9FA;
}

.logo-star {
    height: 55px;
    width: 55x;
}


.logo-tittle {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
}


.logout-button {
    right: 61px;
    top: 9px;
}

.login-container {
    width: 700px;
    height: 356px;
    margin-right: auto;
    margin-left: auto;

    margin-top: 50px;

    background: #FFFFFF;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.13);
    border-radius: 29px;
}


.Login-title {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 50px;
    margin-top: 50px;

    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;

    color: #152536;
}